import React, { useState } from "react";
import axios from "axios";

import Button from "@material-ui/core/Button";
import IconDone from "@material-ui/icons/Done";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import DataLoader from "../DataLoader";

export default function BlacklistUser({ user, checked, blacklistCallback }) {
  const [blacklisted, setBlacklisted] = useState(false);
  const [loading, setLoading] = useState(false);

  const blacklistUser = (uid) => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_HOST}/users/${uid}/blacklist/`, {
        headers: {
          auth_token: `${localStorage.getItem("auth")}`,
        },
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setBlacklisted(true);
        blacklistCallback();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Button
      variant="contained"
      color=""
      onClick={(e) => blacklistUser(user.user_id)}
      disabled={!checked || blacklisted ? true : false}
      style={{
        marginLeft: "5px",
        minHeight: "2.25rem",
        lineHeight: "1.25",
        backgroundColor: checked && !blacklisted && "#c40000",
        color: checked && !blacklisted && "#ffffff",
      }}
    >
      {loading ? (
        <DataLoader
          color="rgba(0, 0, 0, 0.87)"
          styles={{
            minHeight: 0,
            display: "flex",
          }}
          height="10px"
        />
      ) : blacklisted ? (
        <IconDone />
      ) : (
        <DeleteForeverIcon />
      )}
    </Button>
  );
}
