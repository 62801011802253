import React from "react";
import { Helmet } from "react-helmet";

import Grid from "@material-ui/core/Grid";

// Components
import Elearning from "./Elearning";

export default function Elearnings({ elearningDisplayData }) {
  return (
    <>
      <Helmet>
        <title>Bayer eLearning |Punktefreischaltung</title>
      </Helmet>
      <h1>eLearning Punkte&shy;freischaltung</h1>
      <hr />
      <Grid container spacing={3}>
        {elearningDisplayData.map(({ id, title, image, logo }) => {
          return (
            <Grid key={id} item sm={12} md={6} lg={4}>
              <Elearning id={id} title={title} image={image} logo={logo} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
