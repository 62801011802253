import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

export default function BayerProvider(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/auth`)
      .then((response) => {
        // TODO Use Context to store Auth-Token
        localStorage.setItem("auth", response.data.auth_token);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <main className="content">
      {loading ? <h1>Authentifizierung läuft...</h1> : props.children}
    </main>
  );
}
