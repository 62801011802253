import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import DataLoader from "../DataLoader";
import styles from "./index.module.css";

import Tooltip from "@material-ui/core/Tooltip";

export default function Elearning({ id, title, image, logo }) {
  const [loading, setLoading] = useState(true);
  // const [vouchersAvailable, setVouchersAvailable] = useState(0);
  const [vouchersReserved, setVouchersReserved] = useState(0);

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_API_HOST}/vouchers/${id}`,
      `${process.env.REACT_APP_API_HOST}/vouchers/${id}/reserved`,
    ];

    axios
      .all(
        endpoints.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              auth_token: `${localStorage.getItem("auth")}`,
            },
          })
        )
      )
      .then(
        axios.spread((total, reserved, users) => {
          // setVouchersAvailable(total.data);
          setVouchersReserved(reserved.data);
          setLoading(false);
        })
      );
  }, [id]);

  return (
    <Link
      to={`/vouchers/${id}`}
      className={`${styles.campaign} ${
        (!vouchersReserved.length) &&
        "disabled-link"
      }`}
    >
      <figure>
        {loading && <DataLoader />}
        <img
          src={logo}
          onLoad={() => {
            setLoading(false);
          }}
          alt=""
          style={{ display: loading ? "none" : "block" }}
          className={styles.logo}
        ></img>
        <img
          src={image}
          alt=""
          onLoad={() => {
            setLoading(false);
          }}
          style={{ display: loading ? "none" : "block" }}
          className={styles.thumbnail}
        />
        <figcaption>
          <h2 title={title} dangerouslySetInnerHTML={{ __html: title }}></h2>
          <Tooltip title="Freizuschaltende Bonuspunkte" arrow placement="top">
            <span className={styles.amountReserved}>
              {vouchersReserved.length ? vouchersReserved[0].amount : "0"}
            </span>
          </Tooltip>
        </figcaption>
      </figure>
    </Link>
  );
}
