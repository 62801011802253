import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function DataLoader(props) {
  const defaultProps = {
    height: "35px",
    color: "#89d329",
  };

  props = { ...defaultProps, ...props };

  return (
    <span className="dataLoader" style={props.styles}>
      <ScaleLoader
        color={props.color}
        height={props.height}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </span>
  );
}
