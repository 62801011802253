import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";

import UserDetails from "../UserDetails";

import styles from "./index.module.css";

export default function ElearningDetails({ elearningDisplayData }) {
  let { id } = useParams();

  const [title, setTitle] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(true);
  // const [vouchersAvailable, setVouchersAvailable] = useState(0);
  const [vouchersReserved, setVouchersReserved] = useState(0);
  const [vouchersReservedUsers, setVouchersReservedUsers] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_API_HOST}/vouchers/${id}`,
      `${process.env.REACT_APP_API_HOST}/vouchers/${id}/reserved`,
      `${
        process.env.REACT_APP_API_HOST
      }/vouchers/${id}/reserved/users?limit=${rowsPerPage}&offset=${
        page * rowsPerPage
      }`,
    ];

    setTitle(() => {
      let { title } = elearningDisplayData.find(
        (elearning) => elearning.id === parseInt(id)
      );
      return title;
    });

    axios
      .all(
        endpoints.map((endpoint) =>
          axios.get(endpoint, {
            headers: {
              auth_token: `${localStorage.getItem("auth")}`,
            },
          })
        )
      )
      .then(
        axios.spread((total, reserved, users) => {
          // setVouchersAvailable(total.data[0]);
          setVouchersReserved(reserved.data[0]);
          setVouchersReservedUsers(users.data);
          setLoading(false);
        })
      );
  }, [id, elearningDisplayData, page, rowsPerPage]);

  return loading ? (
    <h1>Lade Bonuspunktedaten...</h1>
  ) : (
    <>
      <Grid
        container
        spacing={3}
        component="header"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={8}>
          <Link to="/" className={styles.backLink}>
            zurück zur Übersicht
          </Link>
          <h1
            className="textEllipsis"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <Paper className={styles.archiveLink__container}>
            <p>
              Reservierte Bonuspunkte:
              <span className={styles.amountReserved}>
                {vouchersReserved.amount}
              </span>
            </p>
          </Paper>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={3} component="section" justifyContent="center">
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Vorname</TableCell>
                  <TableCell>Nachname</TableCell>
                  <TableCell>E-Mail Adresse</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      Daten werden geladen...
                    </TableCell>
                  </TableRow>
                ) : (
                  vouchersReservedUsers.map((user) => (
                    <UserDetails key={user.user_id} id={id} user={user} />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            backIconButtonText="Vorherige Seite"
            nextIconButtonText="Nächste Seite"
            labelRowsPerPage="Zeilen pro Seite"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} von ${count}`
            }
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={vouchersReserved.amount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
}
