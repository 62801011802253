import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// Components
import BayerProvider from "./components/BayerProvider";
import Elearnings from "./components/Elearnings";
import ElearningDetails from "./components/ElearningDetails";

const BayerTheme = createTheme({
  themeName: "Bayer Theme",
  palette: {
    type: "light",
    primary: {
      main: "#89d329",
    },
    secondary: {
      main: "#00bcff",
    },
    error: {
      main: "#c40000",
    },
    success: {
      main: "#89d329",
    },
    text: {
      primary: "#000000",
    },
    divider: "#000000",
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 18,
    htmlFontSize: 18,
    h1: {
      fontSize: "3.2em",
    },
    h2: {
      fontSize: "2.6em",
    },
    h3: {
      fontSize: "2.2em",
    },
    h4: {
      fontSize: "1.8em",
    },
    h5: {
      fontSize: "1.6em",
    },
    h6: {
      fontSize: "1.2em",
    },
    button: {
      fontSize: "1em",
      textTransform: "none",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiTablePagination: {
      selectIcon: {
        top: "calc(50% - 16px)",
      },
    },
  },
});

const elearningDisplayData = [
  {
    id: 2,
    title: "Laif<sup>&reg;</sup> 900 Balance",
    image:
      "https://elearning.bayer.de/images/portal/tile_bg_laif900balance.jpg",
    logo: "https://elearning.bayer.de/images/laif900/laif900-balance-logo.svg",
  },
  // {
  //   id: 3,
  //   title: "Iberogast<sup>&reg;</sup>",
  //   image: "https://elearning.bayer.de/images/portal/tile_bg_iberogast.jpg",
  //   logo: "",
  // },
  {
    id: 38,
    title: "Talcid<sup>&reg;</sup>-Video",
    image: "https://elearning.bayer.de//images/talcidvideo/tile_bg_talcidvideo.jpg",
    logo: "https://elearning.bayer.de/images/talcid/talcid-logo.svg",
  },
  {
    id: 9,
    title: "Lefax<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/portal/tile_bg_lefax.jpg",
    logo: "https://elearning.bayer.de/images/lefax/lefax-logo.svg",
  },
  {
    id: 17,
    title: "Calmalaif<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/calmalaif/tile_bg_calmalaif.jpg",
    logo: "https://elearning.bayer.de/images/calmalaif/calmalaif-logo.png",
  },
  {
    id: 18,
    title: "Bepanthol<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/bepanthol/tile_bg_bepanthol.jpg",
    logo: "https://elearning.bayer.de/images/bepanthol/bepanthol-logo.png",
  },
  {
    id: 19,
    title: "Phytohustil<sup>&reg;</sup>",
    image:
      "https://elearning.bayer.de/images/phytohustil/tile_bg_phytohustilvideo.jpg",
    logo: "https://elearning.bayer.de/images/phytohustil/phytohustil-logo-alt.svg",
  },
  {
    id: 20,
    title: "Aspirin<sup>&reg;</sup> Complex",
    image:
      "https://elearning.bayer.de/images/aspirincomplex/tile_bg_aspirincomplexvideo2.jpg",
    logo: "https://elearning.bayer.de/images/aspirincomplex/aspirin-complex-logo@2x.png",
  },
  {
    id: 21,
    title: "Gastro Experience",
    image: "https://elearning.bayer.de/images/gastro/tile_bg_gastrovideo.jpg",
    logo: "",
  },
  {
    id: 23,
    title: "Elevit<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/elevitvideo/tile_bg_elevitvideo.jpg",
    logo: "",
  },
  {
    id: 25,
    title: "Priorin<sup>&reg;</sup> Symposium",
    image: "https://elearning.bayer.de/images/priorin-symposium/tile_bg_priorin-symposium.jpg",
    logo: "https://elearning.bayer.de/images/priorin-symposium/priorin-logo-white.svg",
  },
  {
    id: 26,
    title: "Aspirin<sup>&reg;</sup> Plus C",
    image: "https://elearning.bayer.de/images/aspirinplusc/tile_bg_aspirinplusc.jpg",
    logo: "",
  },
  {
    id: 27,
    title: "Canesten<sup>&reg;</sup> EXTRA",
    image: "https://elearning.bayer.de/images/canestenvideo/tile_bg_canestenvideo.jpg",
    logo: "",
  },
  {
    id: 28,
    title: "Lunalaif<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/lunalaifvideo/tile_bg_lunalaifvideo.jpg",
    logo: "",
  },  
  {
    id: 29,
    title: "Iberogast<sup>&reg;</sup> &amp; Plastic Bank",
    image: "https://elearning.bayer.de/images/iberogastplasticbank/tile_bg_iberogastplasticbank.jpg",
    logo: "",
  },
  {
    id: 30,
    title: "Priorin<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/priorinvideo/tile_bg_priorinvideo.jpg",
    logo: "https://elearning.bayer.de/images/priorinvideo/priorin-logo.svg",
  },
  {
    id: 31,
    title: "Aspirin<sup>&reg;</sup> Studie",
    image: "https://elearning.bayer.de/images/aspirinstudie/tile_bg_aspirinstudie.jpg",
    logo: "",
  },
  {
    id: 33,
    title: "Iberogast<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/iberogastvideo/tile_bg_iberogastvideo.jpg",
    logo: "",
  },  
  {
    id: 34,
    title: "Bepanthen<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/bepanthenvideo/tile_bg_bepanthenvideo.jpg",
    logo: "https://elearning.bayer.de/images/bepanthenvideo/bepanthen_logo.png",
  },  
  {
    id: 35,
    title: "IberoBiotics<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/iberobioticsvideo/tile_bg_iberobioticsvideo.jpg",
    logo:  "https://elearning.bayer.de/images/iberobioticsvideo/iberobioticsvideo_logo.png",
  },  
  {
    id: 36,
    title: "IberoBiotics-Messe<sup>&reg;</sup>",
    image: "https://elearning.bayer.de/images/iberobioticsvideo/tile_bg_iberobioticsvideo.jpg",
    logo:  "https://elearning.bayer.de/images/iberobioticsvideo/iberobioticsvideo_logo.png",
  }, 
  {
    id: 37,
    title: "Aspirin<sup>&reg;</sup> Pain",
    image: "https://elearning.bayer.de/images/aspirinpainvideo/tile_bg_aspirinpainvideo.jpg",
    logo:  "",
  },    
];

function App() {
  return (
    <Router>
      <ThemeProvider theme={BayerTheme}>
        <CssBaseline />
        <BayerProvider>
          <Switch>
            <Route exact path="/">
              <Elearnings elearningDisplayData={elearningDisplayData} />
            </Route>
            <Route
              path="/vouchers/:id"
              children={
                <ElearningDetails elearningDisplayData={elearningDisplayData} />
              }
            />
          </Switch>
        </BayerProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
