import React, { useState } from "react";
import axios from "axios";

import Button from "@material-ui/core/Button";
import IconDone from "@material-ui/icons/Done";

import DataLoader from "../DataLoader";

export default function AssignUser({ el_id, user, checked }) {
  const [assigned, setAssigned] = useState(false);
  const [loading, setLoading] = useState(false);

  const activateVoucher = (el_id, uid) => {
    setLoading(true);

      axios.get(
          `https://bayer-elearning.stageii.janus-wa.de/api/shopware/${uid}/${el_id}`,
          {
              headers: {
                  'Authorization': `Bearer ${process.env.BAYER_ELEARNING_APP_API_TOKEN}`,
              },
          }
      )

      .then((response) => {
        console.log(response);
        setLoading(false);
        setAssigned(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Button
      variant="contained"
      startIcon={assigned && <IconDone />}
      color="primary"
      onClick={(e) => activateVoucher(parseInt(el_id), user.user_id)}
      disabled={!checked || assigned ? true : false}
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        minHeight: "2.25rem",
        lineHeight: "1.25",
      }}
    >
      {loading ? (
        <DataLoader
          color="rgba(0, 0, 0, 0.87)"
          styles={{
            minHeight: 0,
            display: "flex",
          }}
          height="10px"
        />
      ) : assigned ? (
        "Bonuspunkte an Shop gesendet"
      ) : (
        "Bonuspunkte an Shop senden"
      )}
    </Button>
  );
}
