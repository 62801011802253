import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InfoIcon from "@material-ui/icons/Info";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Tooltip from "@material-ui/core/Tooltip";

import AssignUser from "../Functional/AssignUser";
import BlacklistUser from "../Functional/BlacklistUser";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  pharmacyInfo: {
    "&": {
      margin: "16px 0 32px",
    },
  },
});

export default function UserDetails({ id, user }) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const classes = useRowStyles();

  const handleBlacklistCallback = () => {
    console.log("blacklisted");
  };
  const blacklistedUser = () =>
    user.password === "a00f4ca8325fe3a85b60ad40e77655b8" ? true : false;
  return (
    <>
      <TableRow className={classes.root} key={user.user_id} hover>
        <TableCell>
          <Tooltip title="Apothekendaten" arrow placement="top">
            <IconButton
              aria-label="expand row"
              size="small"
              style={{
                borderRadius: "100px",
              }}
              onClick={() => {
                setOpen(!open);
                setChecked(true);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              <InfoIcon color={checked ? "default" : "primary"} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell scope="row">{user.firstname}</TableCell>
        <TableCell scope="row">{user.lastname}</TableCell>
        <TableCell scope="row">
          {user.email}
          {blacklistedUser() && (
            <ReportProblemOutlinedIcon
              color="error"
              style={{
                verticalAlign: "bottom",
                marginLeft: "10px",
              }}
            />
          )}
        </TableCell>
        <TableCell scope="row" align="right">
          <Tooltip
            title="Bitte erst Apothekendaten überprüfen"
            arrow
            placement="top"
            disableHoverListener={checked}
          >
            <ButtonGroup>
              {!blacklistedUser() && (
                <AssignUser
                  el_id={parseInt(id)}
                  user={user}
                  checked={checked}
                />
              )}
              <BlacklistUser
                user={user}
                checked={checked}
                blacklistCallback={handleBlacklistCallback}
              />
            </ButtonGroup>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer className={classes.pharmacyInfo}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <h4>Apothekendaten</h4>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Straße</TableCell>
                    <TableCell>Postleitzahl</TableCell>
                    <TableCell>Ort</TableCell>
                    <TableCell>Telefon</TableCell>
                    <TableCell>Fax</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{user.pharmacy_name}</TableCell>
                    <TableCell>{user.pharmacy_street}</TableCell>
                    <TableCell>{user.pharmacy_postalcode}</TableCell>
                    <TableCell>{user.pharmacy_city}</TableCell>
                    <TableCell>{user.pharmacy_phone}</TableCell>
                    <TableCell>{user.pharmacy_fax}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
